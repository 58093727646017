import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeMiddleService {

  constructor() {}

  employeesGridFilter: any;
  expiringEligibilitiesGridFilter: any;

}
