import { inject } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import { UserDetailService } from '../services/user-detail.service';
import { AuthService } from '../services/auth.service';
import { AppRoutes } from './app-routes';

export const roleAccessGuard: CanActivateFn = (route:ActivatedRouteSnapshot,  state: RouterStateSnapshot) => {
  const authService = inject(AuthService);
  const userDetailService = inject(UserDetailService);
  const router = inject(Router);

  if (!authService.loggedIn.value) {
    return router.createUrlTree([AppRoutes.LOGIN]);
  }

  const role = userDetailService.role;
  const allowedRoles = route.data?.['roles'];

  if (!role || (allowedRoles && !allowedRoles.includes(role))) {
    localStorage.setItem('attemptedUrl', state.url);
    return router.createUrlTree([AppRoutes.NO_PERMISSION]);
  }

  return true;
};
