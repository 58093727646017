export enum AppRoutes {
  LOGIN = 'prihlasenie',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-hesla',
  FORGOTTEN_PASSWORD = 'zabudnute-heslo',
  ACCEPT_INVITATION = 'accept-invitation',
  NO_PERMISSION = 'no-permission',
  SMALL_SCREEN = 'small-screen',
  INVITATION_CODE = 'invitation-code',

  /** ADMIN **/
  IMPORT = 'import',
  QRCODE = 'qrcode',
  TEMPLATE = 'template',
  EMPLOYERS = 'zamestnavatel',
  PROVIDERS = 'poskytovatel',

  /** PZS AMD HR **/
  SETTINGS_PROVIDER = 'nastavenia-poskytovatel',
  REGISTERED_USERS = 'registrovany-pouzivatelia',
  CATEGORIZATION_PROVIDERS = 'kategorizator',
  EMPLOYEES = 'zamestnanci',
  USER_CALENDAR = 'kalendar',
  ASSESSMENTS = 'posudky',
  ORDERS = 'objednavky',

  /** ALL **/
  HOME = 'domov',
  NEW_SETTINGS = 'nastavenia',
  MAIN_PAGE = '',
  PROFILE = 'profil',
  SETTING = 'nastavenia',
}

export enum AppRoutesAccount {
  ACCOUNT = 'account',
}

export enum AppRoutesAssessment {
  CLOSE_ASSESSMENT = 'uzavrete',
  OPEN_ASSESSMENT = 'otvorene',
  ASSESSMENT = 'posudok',
}

export enum AppRoutesOrder {
  ORDER_IN_ADVANCE = 'predbezne',
  ORDER_NEW = 'odoslaneNove',
  ORDER_PLANNED = 'naplanovane',
}

export enum AppRoutesMedical {
  MEDICAL_CALENDAR = 'kalendar',
  MEDICAL_EXECUTIONS = 'vykony',
  MEDICAL_PRICE_LIST = 'cennik',
  MEDICAL_PRICE_LISTS = 'cenniky',
}

export enum AppRoutesSetting {
  SETTING_PREVIEW_USERS = 'prehlad-pouzivatelov',
  SETTING_PREVIEW_EMPLOYERS = 'prehlad-zamestnavatelov',
  SETTING_RISK_FACTOR_AND_WORK_ACTIVITY = 'rizikove-faktory-a-pracovne-aktivity',
  SETTING_PROFESSION = 'profesie',
  SETTING_WORKPLACE = 'pracoviska',
  SETTING_MEDICAL_EXECUTIONS = 'medicinske-vykony',
  OCCUPATIONS = 'profesie',
  WORKPLACES = 'pracoviska',
  CATEGORIZATIONS = 'kategorizacie',
  CATEGORIZATION = 'kategorizacia',
}
