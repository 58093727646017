/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CapabilityType } from './capabilityType';
import { AssessmentResult } from './assessmentResult';


export interface ExpiringAssessiblesItemsApiModel { 
    capabilityDefinitionsId: number;
    assessedItemId?: number;
    itemType: CapabilityType;
    name: string;
    validTo?: string;
    pending: boolean;
    category?: number;
    occupationId?: number;
    eligibility?: AssessmentResult;
    isMandatory: boolean;
}

