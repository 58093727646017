// config is loaded in index.html

export function getBasePath(): string {
  return (<any>window).medifioriConfig.basePath
}

export function getProductionEnabled(): string {
  return (<any>window).medifioriConfig.production
}

export function getTurnstileSiteKey(): string {
  return (<any>window).medifioriConfig.turnstileSiteKey
}
