import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import { AuthService as AuthApiService } from '../../api-new';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import {AppRoutes} from "../guards/app-routes";

export enum UserRole {
  human_resource_manager = 'human_resource_manager',
  health_care_manager = 'health_care_manager',
  pzs_manager = 'pzs_manager',
  system_manager = 'system_manager'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn = new BehaviorSubject<boolean>(false);
  jwt?: string;

  constructor(
    private authApiService: AuthApiService,
    private router: Router,
    private toaster: NbToastrService,
  ) {
    this.jwt = this.getLocalToken();
    this.loggedIn.next(!!this.jwt);
  }

  logIn(userName: string, password: string) {
    this.authApiService.authLogin({userName, password}).subscribe(
      jwt => {
        this.jwt = jwt;
        this.saveLocalToken(jwt);
        this.loggedIn.next(true);
        this.router.navigate([AppRoutes.HOME]);
      },
      errorResponse => {
        if (errorResponse.status === 401) {
          this.toaster.danger('Nesprávne prihlasovacie údaje', 'Chyba');
        }
      }
    );
  }

  forceLogIn(userId: number) {
    this.authApiService.authForceLogin(userId).subscribe(
      jwt => {
        this.jwt = jwt;
        this.loggedIn.next(true);
        this.saveLocalToken(jwt);
        this.router.navigate([AppRoutes.HOME]);
      },
      errorResponse => {
        if (errorResponse.status === 401) {
          this.toaster.danger('Nesprávne prihlasovacie údaje', 'Chyba');
        }
      }
    );
  }

  logOut() {
    this.jwt = undefined;
    localStorage.removeItem('jwt');
    localStorage.removeItem('attemptedUrl');
    this.loggedIn.next(false);
    this.router.navigate([AppRoutes.LOGIN]);
  }

  private getLocalToken() {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      return JSON.parse(jwt);
    }
    return undefined;
  }

  private saveLocalToken(jwt) {
    localStorage.setItem('jwt', JSON.stringify(jwt));
  }
}
