import { Routes } from '@angular/router';
import {AppRoutes} from "./guards/app-routes";
import {roleAccessGuard} from "./guards/role-access.guard";
import {loggedInGuard} from "./guards/logged-in.guard";

export const routes: Routes = [
  {
    path: AppRoutes.LOGIN,
    canActivate: [loggedInGuard],
    loadChildren: () => import('../app/modules/auth/routes').then(m => m.AUTH_ROUTES)
  },
  {
    path: AppRoutes.MAIN_PAGE,
    canActivate: [roleAccessGuard],
    loadChildren: () => import('../app/modules/interface/routes').then(m => m.INTERFACE_ROUTES)
  },
  {
    path: AppRoutes.FORGOTTEN_PASSWORD,
    loadChildren: () => import('../app/modules/forgotten-password/routes').then(m => m.FORGOTTEN_PASSWORD_ROUTES)
  },
  {
    path: AppRoutes.RESET_PASSWORD,
    loadChildren: () => import('../app/modules/reset-password/routes').then(m => m.RESET_PASSWORD_ROUTES)
  },
  {
    path: AppRoutes.ACCEPT_INVITATION,
    loadChildren: () => import('../app/modules/accept-invitation/routes').then(m => m.ACCEPT_INVITATION_ROUTES)
  },
  {
    path: AppRoutes.REGISTER,
    loadChildren: () => import('../app/modules/account/routes').then(m => m.ACCOUNT_ROUTES)
  },
  {
    path: '**',
    redirectTo: AppRoutes.LOGIN,
    pathMatch: 'full',
  }
];
