// @ts-nocheck
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AssignableCapabilityApiModel } from '../model/models';
import { CreateEmployeeApiModel } from '../model/models';
import { EmployeeActiveState } from '../model/models';
import { EmployeeContractApiModel } from '../model/models';
import { EmployeeContractCreateApiModel } from '../model/models';
import { EmployeeContractImportApiModel } from '../model/models';
import { EmployeeContractUpdateApiModel } from '../model/models';
import { EmployeeDataApiModel } from '../model/models';
import { EmployeeDetailApiModel } from '../model/models';
import { EmployeeDetailApiModelNew } from '../model/models';
import { EmployeeDetailsUpdateApiModel } from '../model/models';
import { EmployeeExclusiveCapabilityNewApiModel } from '../model/models';
import { EmployeeExpiringAssessiblesApiModel } from '../model/models';
import { EmployeeExpiringAssessiblesListPageApiModel } from '../model/models';
import { EmployeeGridListApiModel } from '../model/models';
import { EmployeeImportApiModel } from '../model/models';
import { HttpError } from '../model/models';
import { ImportAssessedRecordsApiModel } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

    protected basePath = 'https://localhost:61175';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param newEmployeeData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModel>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModel>>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModel>>;
    public employeeCreateEmployee(newEmployeeData: CreateEmployeeApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newEmployeeData === null || newEmployeeData === undefined) {
            throw new Error('Required parameter newEmployeeData was null or undefined when calling employeeCreateEmployee.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EmployeeDetailApiModel>(`${this.configuration.basePath}/api/Employee`,
            newEmployeeData,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param employeeContractCreateApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeContractApiModel>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeContractApiModel>>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeContractApiModel>>;
    public employeeCreateEmployeeContract(id: number, employeeContractCreateApiModel: EmployeeContractCreateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeCreateEmployeeContract.');
        }
        if (employeeContractCreateApiModel === null || employeeContractCreateApiModel === undefined) {
            throw new Error('Required parameter employeeContractCreateApiModel was null or undefined when calling employeeCreateEmployeeContract.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<EmployeeContractApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/contract`,
            employeeContractCreateApiModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param additionalEmployeeAssesibleNewApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeCreateExclusiveCapability(additionalEmployeeAssesibleNewApiModel: EmployeeExclusiveCapabilityNewApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any>;
    public employeeCreateExclusiveCapability(additionalEmployeeAssesibleNewApiModel: EmployeeExclusiveCapabilityNewApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<any>>;
    public employeeCreateExclusiveCapability(additionalEmployeeAssesibleNewApiModel: EmployeeExclusiveCapabilityNewApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<any>>;
    public employeeCreateExclusiveCapability(additionalEmployeeAssesibleNewApiModel: EmployeeExclusiveCapabilityNewApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (additionalEmployeeAssesibleNewApiModel === null || additionalEmployeeAssesibleNewApiModel === undefined) {
            throw new Error('Required parameter additionalEmployeeAssesibleNewApiModel was null or undefined when calling employeeCreateExclusiveCapability.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/Employee/exclusive-capability`,
            additionalEmployeeAssesibleNewApiModel,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param capabilityId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeDeleteExclusiveCapability(employeeId?: number, capabilityId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public employeeDeleteExclusiveCapability(employeeId?: number, capabilityId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public employeeDeleteExclusiveCapability(employeeId?: number, capabilityId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public employeeDeleteExclusiveCapability(employeeId?: number, capabilityId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employeeId !== undefined && employeeId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employeeId, 'employeeId');
        }
        if (capabilityId !== undefined && capabilityId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>capabilityId, 'capabilityId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.delete(`${this.configuration.basePath}/api/Employee/exclusive-capability`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGet(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModelNew>;
    public employeeGet(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModelNew>>;
    public employeeGet(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModelNew>>;
    public employeeGet(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGet.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeDetailApiModelNew>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetAssignableRiskFactors(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<AssignableCapabilityApiModel>>;
    public employeeGetAssignableRiskFactors(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<AssignableCapabilityApiModel>>>;
    public employeeGetAssignableRiskFactors(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<AssignableCapabilityApiModel>>>;
    public employeeGetAssignableRiskFactors(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGetAssignableRiskFactors.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AssignableCapabilityApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/assignableRiskFactors`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetAssignableWorkItems(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<AssignableCapabilityApiModel>>;
    public employeeGetAssignableWorkItems(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<AssignableCapabilityApiModel>>>;
    public employeeGetAssignableWorkItems(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<AssignableCapabilityApiModel>>>;
    public employeeGetAssignableWorkItems(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling employeeGetAssignableWorkItems.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<AssignableCapabilityApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(id))}/assignableWorkItems`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetEligibles(employeeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeExpiringAssessiblesApiModel>;
    public employeeGetEligibles(employeeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeExpiringAssessiblesApiModel>>;
    public employeeGetEligibles(employeeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeExpiringAssessiblesApiModel>>;
    public employeeGetEligibles(employeeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeGetEligibles.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeExpiringAssessiblesApiModel>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(employeeId))}/eligibilities`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     * @deprecated
     */
    public employeeGetEmployeesCount(employerId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDataApiModel>;
    public employeeGetEmployeesCount(employerId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDataApiModel>>;
    public employeeGetEmployeesCount(employerId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDataApiModel>>;
    public employeeGetEmployeesCount(employerId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employerId === null || employerId === undefined) {
            throw new Error('Required parameter employerId was null or undefined when calling employeeGetEmployeesCount.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeDataApiModel>(`${this.configuration.basePath}/api/Employee/count/${encodeURIComponent(String(employerId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param employeeActiveStates 
     * @param textSearch 
     * @param showOnlyExpired 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetEmployeesGridCount(employerId?: number, employeeActiveStates?: Array<EmployeeActiveState>, textSearch?: string, showOnlyExpired?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDataApiModel>;
    public employeeGetEmployeesGridCount(employerId?: number, employeeActiveStates?: Array<EmployeeActiveState>, textSearch?: string, showOnlyExpired?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDataApiModel>>;
    public employeeGetEmployeesGridCount(employerId?: number, employeeActiveStates?: Array<EmployeeActiveState>, textSearch?: string, showOnlyExpired?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDataApiModel>>;
    public employeeGetEmployeesGridCount(employerId?: number, employeeActiveStates?: Array<EmployeeActiveState>, textSearch?: string, showOnlyExpired?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'EmployerId');
        }
        if (employeeActiveStates) {
            employeeActiveStates.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'EmployeeActiveStates');
            })
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>textSearch, 'TextSearch');
        }
        if (showOnlyExpired !== undefined && showOnlyExpired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showOnlyExpired, 'ShowOnlyExpired');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeDataApiModel>(`${this.configuration.basePath}/api/Employee/grid-employees/count`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param page 
     * @param onPage 
     * @param showExpired 
     * @param showPlaned 
     * @param limitExpiredDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeExpiringAssessiblesListPageApiModel>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeExpiringAssessiblesListPageApiModel>>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeExpiringAssessiblesListPageApiModel>>;
    public employeeGetExpiringEligibilitiesNew(employerId?: number, page?: number, onPage?: number, showExpired?: boolean, showPlaned?: boolean, limitExpiredDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (onPage !== undefined && onPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>onPage, 'onPage');
        }
        if (showExpired !== undefined && showExpired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showExpired, 'showExpired');
        }
        if (showPlaned !== undefined && showPlaned !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showPlaned, 'showPlaned');
        }
        if (limitExpiredDate !== undefined && limitExpiredDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limitExpiredDate, 'limitExpiredDate');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<EmployeeExpiringAssessiblesListPageApiModel>(`${this.configuration.basePath}/api/Employee/expiringEligibilitiesNew`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employerId 
     * @param page 
     * @param onPage 
     * @param textSearch 
     * @param showOnlyExpired 
     * @param employeeActiveStates 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, showOnlyExpired?: boolean, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeGridListApiModel>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, showOnlyExpired?: boolean, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeGridListApiModel>>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, showOnlyExpired?: boolean, employeeActiveStates?: Array<EmployeeActiveState>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeGridListApiModel>>>;
    public employeeGetGridData(employerId?: number, page?: number, onPage?: number, textSearch?: string, showOnlyExpired?: boolean, employeeActiveStates?: Array<EmployeeActiveState>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (page !== undefined && page !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>page, 'page');
        }
        if (onPage !== undefined && onPage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>onPage, 'onPage');
        }
        if (textSearch !== undefined && textSearch !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>textSearch, 'textSearch');
        }
        if (showOnlyExpired !== undefined && showOnlyExpired !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>showOnlyExpired, 'showOnlyExpired');
        }
        if (employeeActiveStates) {
            employeeActiveStates.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'employeeActiveStates');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EmployeeGridListApiModel>>(`${this.configuration.basePath}/api/Employee/grid-employees`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param newContracts 
     * @param employerId 
     * @param validateOnly 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeImportEmployeeContracts(newContracts: Array<EmployeeContractImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeContractImportApiModel>>;
    public employeeImportEmployeeContracts(newContracts: Array<EmployeeContractImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeContractImportApiModel>>>;
    public employeeImportEmployeeContracts(newContracts: Array<EmployeeContractImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeContractImportApiModel>>>;
    public employeeImportEmployeeContracts(newContracts: Array<EmployeeContractImportApiModel>, employerId?: number, validateOnly?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newContracts === null || newContracts === undefined) {
            throw new Error('Required parameter newContracts was null or undefined when calling employeeImportEmployeeContracts.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (validateOnly !== undefined && validateOnly !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>validateOnly, 'validateOnly');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EmployeeContractImportApiModel>>(`${this.configuration.basePath}/api/Employee/import-contracts`,
            newContracts,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param newEmployees 
     * @param employerId 
     * @param validateOnly 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeImportEmployees(newEmployees: Array<EmployeeImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeImportApiModel>>;
    public employeeImportEmployees(newEmployees: Array<EmployeeImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeImportApiModel>>>;
    public employeeImportEmployees(newEmployees: Array<EmployeeImportApiModel>, employerId?: number, validateOnly?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeImportApiModel>>>;
    public employeeImportEmployees(newEmployees: Array<EmployeeImportApiModel>, employerId?: number, validateOnly?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newEmployees === null || newEmployees === undefined) {
            throw new Error('Required parameter newEmployees was null or undefined when calling employeeImportEmployees.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (employerId !== undefined && employerId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>employerId, 'employerId');
        }
        if (validateOnly !== undefined && validateOnly !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>validateOnly, 'validateOnly');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Array<EmployeeImportApiModel>>(`${this.configuration.basePath}/api/Employee/import`,
            newEmployees,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param importExistingAssessedRecordsApiModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Blob>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Blob>>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Blob>>;
    public employeeImportExistingAssessedRecords(importExistingAssessedRecordsApiModel: ImportAssessedRecordsApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        if (importExistingAssessedRecordsApiModel === null || importExistingAssessedRecordsApiModel === undefined) {
            throw new Error('Required parameter importExistingAssessedRecordsApiModel was null or undefined when calling employeeImportExistingAssessedRecords.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/Employee/importExistingAssessedRecords`,
            importExistingAssessedRecordsApiModel,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employeeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<Array<EmployeeContractApiModel>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<Array<EmployeeContractApiModel>>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<Array<EmployeeContractApiModel>>>;
    public employeeLoadEmployeeContracts(employeeId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employeeId === null || employeeId === undefined) {
            throw new Error('Required parameter employeeId was null or undefined when calling employeeLoadEmployeeContracts.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<EmployeeContractApiModel>>(`${this.configuration.basePath}/api/Employee/${encodeURIComponent(String(employeeId))}/contract`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param newContract 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeContractApiModel>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeContractApiModel>>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeContractApiModel>>;
    public employeeUpdateEmployeeContract(newContract: EmployeeContractUpdateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (newContract === null || newContract === undefined) {
            throw new Error('Required parameter newContract was null or undefined when calling employeeUpdateEmployeeContract.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<EmployeeContractApiModel>(`${this.configuration.basePath}/api/Employee/contract`,
            newContract,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param employee 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<EmployeeDetailApiModelNew>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpResponse<EmployeeDetailApiModelNew>>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<HttpEvent<EmployeeDetailApiModelNew>>;
    public employeeUpdatePersonalData(employee: EmployeeDetailsUpdateApiModel, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'}): Observable<any> {
        if (employee === null || employee === undefined) {
            throw new Error('Required parameter employee was null or undefined when calling employeeUpdatePersonalData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (JWT token) required
        credential = this.configuration.lookupCredential('JWT token');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<EmployeeDetailApiModelNew>(`${this.configuration.basePath}/api/Employee/personaldata`,
            employee,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
