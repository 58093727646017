/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 */
export type CapabilityType = 'RISK_FACTOR' | 'WORK_ACTIVITY' | 'COURSE';

export const CapabilityType = {
    RISKFACTOR: 'RISK_FACTOR' as CapabilityType,
    WORKACTIVITY: 'WORK_ACTIVITY' as CapabilityType,
    COURSE: 'COURSE' as CapabilityType
};

