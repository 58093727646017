/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EmployeeImportApiModel { 
    name: string;
    surname: string;
    dateOfBirth: string;
    personalNumber?: string;
    email?: string;
    phone?: string;
    birthNumber?: string;
    entryExaminationCompleted: boolean;
    streetName: string;
    cityName: string;
    zipValue: string;
    countryName: string;
    registerNumber: number;
    descriptiveNumber: string;
    errorMessages?: Array<string>;
}

