/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressNewApiModel } from './addressNewApiModel';
import { TenantCreateApiModel } from './tenantCreateApiModel';
import { UserRegistrationApiModel } from './userRegistrationApiModel';
import { ProviderTenantCreateApiModelAllOf } from './providerTenantCreateApiModelAllOf';


export interface ProviderTenantCreateApiModel { 
    user: UserRegistrationApiModel;
    tenantName: string;
    companyIco: string;
    companyName: string;
    addressDto: AddressNewApiModel;
}

