import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, take } from 'rxjs/operators';
import { AppRoutes } from './app-routes';

export const loggedInGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.loggedIn.pipe(
    take(1),
    map(loggedIn => (loggedIn ? router.createUrlTree([AppRoutes.HOME]) : true))
  );
};
