import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import {Observable} from 'rxjs';
import {EmployeeMiddleService} from '../services/employee-middle.service';
import {AssessmentMiddleService} from '../services/assessment-middle.service';
import {GridfilterService} from '../services/gridfilter.service';
import {getBasePath} from "../../config";

@Injectable()
export class FilterInterceptor implements HttpInterceptor {

  filteredUrls = new Map();

  constructor(
    private employeeService: EmployeeMiddleService,
    private assessmentService: AssessmentMiddleService,
    private gridFilterService: GridfilterService,
  ) {
    this.filteredUrls.set(getBasePath() + '/api/Employee', () => this.employeeService.employeesGridFilter);
    // tslint:disable-next-line:max-line-length
    this.filteredUrls.set(getBasePath() + '/api/Employee/expiringEligibilities', () => this.employeeService.expiringEligibilitiesGridFilter);
    this.filteredUrls.set(getBasePath() + '/api/Assessment/ordersByStatus', () => this.assessmentService.ordersGridFilter);
    // tslint:disable-next-line:max-line-length
    this.filteredUrls.set(getBasePath() + '/api/Assessment/ordersByStatusAndHistorical', () => this.assessmentService.assessmentsGridFilter);
    this.filteredUrls.set(getBasePath() + '/api/Occupation', () => this.gridFilterService.getFilter());
    this.filteredUrls.set(getBasePath() + '/api/Employer/all/paged', () => this.gridFilterService.getFilter());
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.createNewRequest(req));
  }

  private createNewRequest(req: HttpRequest<any>) {
    let newRequest = req.clone();
    if (this.filteredUrls.has(req.url)) {
      let params = req.params;
      const props = this.filteredUrls.get(req.url)();
      // tslint:disable-next-line:forin
      for (const prop in props) {
        params = this.appendToParams(prop, props[prop], params);
      }
      newRequest = req.clone({params});
    }
    return newRequest;
  }

  private appendToParams(name: string, param: any, params: HttpParams): HttpParams {
    let paramsTmp = params;
    // tslint:disable-next-line:triple-equals
    if (typeof param.val != 'undefined' && param.val) {
      paramsTmp = paramsTmp.append(name, param.val);
      // tslint:disable-next-line:triple-equals
      if (typeof param.condition != 'undefined' && param.condition) {
        paramsTmp = paramsTmp.append(name + '_op', param.condition);
      }
    }
    return paramsTmp;
  }
}
