import { Injectable } from '@angular/core';
import { CreateOrderApiModel } from "src/api-new/model/createOrderApiModel";
import {Observable, Subject} from "rxjs";
import {map, tap} from "rxjs/operators";
import {AppointmentService, CompleteOrderApiModel, EligibilityReportService, OrderService} from "../../api-new";
import {HttpResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AssessmentMiddleService {

  assessmentsChangedSubject = new Subject();

  constructor(
    private appointmentService: AppointmentService,
    private orderService: OrderService,
    private eligibilityReportService: EligibilityReportService
  ) { }

  ordersGridFilter: any;
  assessmentsGridFilter: any;

  getNotPlannedOrders(page: number, onPage: number, textSearch: string, gridFilter: any, employerId: number | undefined) {
    this.ordersGridFilter = gridFilter;
    return this.orderService.orderGetGridNotPlannedOrders(
      employerId,
      page+1,
      onPage,
      textSearch
    );
  }


  createOrders(orders: Array<CreateOrderApiModel>) {
    return this.orderService.orderCreateOrder(orders);
  }

  getAssessment(id: number) {
    return this.orderService.orderGetOrderDetails(id);
  }

  confirmOrders(ids: Array<number>) {
    return this.orderService.orderConfirmAssessment(ids);
  }



  deleteAssessment(id: number) {
    return this.orderService.orderDeleteOrder(id).pipe(
      tap(() => this.assessmentsChangedSubject.next())
    );
  }

  completeAssessment(id: number, assessment: CompleteOrderApiModel) {
    return this.orderService.orderCompleteOrder(id, assessment).pipe(
      tap(() => this.assessmentsChangedSubject.next())
    );
  }


  printAssessments(ids: number[]): Observable<File> {
    return this.eligibilityReportService
      .eligibilityReportDownloadEligibilityReportDocuments(ids, 'response')
      .pipe(
        map((response: HttpResponse<Blob>) => {
          const contentDisposition = response.headers.get('Content-Disposition') || '';
          const filename = this.getFilenameFromContentDisposition(contentDisposition);
          return new File([response.body!],filename)
        })
      );
  }

  private getFilenameFromContentDisposition(contentDisposition: string): string {
    let filename = 'default-filename';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

    // Parse filename*= if present (prioritized for UTF-8)
    const utf8FilenameRegex = /filename\*=[^']*''([^;]*)/;
    const utf8FilenameMatch = contentDisposition.match(utf8FilenameRegex);
    if (utf8FilenameMatch && utf8FilenameMatch[1]) {
      return decodeURIComponent(utf8FilenameMatch[1]);
    }

    // Parse filename= as a fallback
    const matches = contentDisposition.match(filenameRegex);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
    return filename;
  }

  getOverdueCount() {
    return this.appointmentService.appointmentGetExpiredAppointmentsCount();
  }
}
