/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CapabilityApiModel } from './capabilityApiModel';
import { CapabilityType } from './capabilityType';
import { CapabilityMedicalExaminationApiModelAllOf } from './capabilityMedicalExaminationApiModelAllOf';
import { MedicalExaminationApiModel } from './medicalExaminationApiModel';
import { CapabilityEligibleIntervalApiModel } from './capabilityEligibleIntervalApiModel';


export interface CapabilityMedicalExaminationApiModel { 
    id: number;
    code: string;
    category?: number;
    mandatory: boolean;
    guid: string;
    type: CapabilityType;
    name: string;
    specification: string;
    eligibleIntervals: Array<CapabilityEligibleIntervalApiModel>;
    medicalExaminations: Array<MedicalExaminationApiModel>;
}

